//@import "../../../node_modules/bootstrap/scss/functions";
//@import "../../../node_modules/bootstrap/scss/variables";
//@import "../../../node_modules/bootstrap/scss/buttons";
//@import '_settings.scss';
//@import '_base.scss';
//@import './components/_header.scss';

/* import only the Bootstrap files needed for customizations */
//@import "~bootstrap/scss/functions";
//@import "~bootstrap/scss/variables";

/* Colors: */

$primary: #48ACF0;
$primary-hover: #3C90C9;
$primary-disable: #78BFF0;
$secondary: #1C6FB3;
$secondary-hover: #16588E;
$secondary-disable: #407FB3;
$success: #20BF55;
$success-hover: #178C3E;
$success-disable: #52B372;
$notification-success: #C3E6CB;
$notification-primary: #CCE5FF;
$notification-light: #E7E8EA;
$notification-warning: #FFF3CD;
$notification-danger: #F8D7DA;
$danger: #EB3339;
$danger-hover: #C52B30;
$danger-disable: #EB6367;
$warning: #FFB100;
$warning-hover: #DE9A00;
$warning-disable: #FFC84D;
$info: #46E0B1;
$info-hover: #36AD89;
$info-disable: #82D4BB;
$dark: #343A40;
$dark-hover: #23272B;
$dark-disable: #7A7E83;
$light: #E9F2FA;
$light-hover: #D2E7F7;
$light-disable: #FAFAFA;
$universal-black: #0E1D29;
$universal-white: #FFFFFF;
$silver-color: #C0C0C0;
$brown-color: #A0522D;
$gold-color: 	#FFD700;
$beige-color: #f5f5dc;
$grey-color: #868E96;

//
// $font-family: "Saira Semi Condensed";
//$font-family-sans-serif: Saira Semi Condensed;
//$font-family-serif:      Saira Semi Condensed;
//$font-family-monospace:  Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
//



//
// $font-family:                 "Saira";
//$font-family-sans-serif:      system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
//$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
//

$box-shadow:                  0 .0rem 1rem rgba($secondary, .15);
//$box-shadow:                  0px 0px 15px 2px #1c6fb329;
$box-shadow-sm:               0 .0rem .75rem rgba($secondary, .125);
$box-shadow-lg:               0 0rem 3rem rgba($secondary, .175);
$box-shadow-inset:            inset 0 1px 2px rgba($secondary, .075);
$headings-font-family:       Saira Semi Condensed !default;
$offcanvas-transition-duration:     .0s;
$form-check-input-checked-bg-color:       $primary;
$form-check-input-checked-border-color:   $primary-hover;
$placeholder-opacity-max:           1;
$form-check-input-width:            1.2em;
$accordion-button-active-bg:        $universal-white;
$accordion-button-focus-box-shadow:        none;
$breadcrumb-divider-color: $grey-color;
$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1140px,
        xxl: 1220px
);

$container-max-width: 1220px;
$spacer: 1rem;
$spacers: (
        0: 0,
        1: $spacer * .25,
        2: $spacer * .5,
        25: $spacer * .75,
        3: $spacer,
        4: $spacer * 1.25,
        5: $spacer * 1.5,
        6: $spacer * 1.75,
);

$form-select-border-color: #E0E0E0;
$form-select-focus-border-color: #E0E0E0;
$form-select-focus-box-shadow: none;
$input-focus-box-shadow: none;
$enable-negative-margins: true;

$theme-colors: (
        notification-success:$notification-success,
        notification-primary:$notification-primary,
        notification-light:$notification-light,
        notification-warning:$notification-warning,
        notification-danger:$notification-danger,
        primary: $primary,
        grey-color: $grey-color,
        primary-hover: $primary-hover,
        primary-disable: $primary-disable,
        secondary: $secondary,
        secondary-hover: $secondary-hover,
        secondary-disable: $secondary-disable,
        success: $success,
        success-hover: $success-hover,
        success-disable: $success-disable,
        danger: $danger,
        danger-hover: $danger-hover,
        danger-disable: $danger-disable,
        warning: $warning,
        warning-hover: $warning-hover,
        warning-disable: $warning-disable,
        info: $info,
        info-hover: $info-hover,
        info-disable: $info-disable,
        dark: $dark,
        dark-hover: $dark-hover,
        dark-disable: $dark-disable,
        light: $light,
        light-hover: $light-hover,
        light-disable: $light-disable,
        universal-black: $universal-black,
        universal-white: $universal-white,
        silver-color: $silver-color,
        brown-color: $brown-color,
        gold-color: $gold-color,
        beige-color: $beige-color,
) !default;


$sl-font-family: Arial, Baskerville, monospace;
$sl-overlay-background: $dark-hover;
$sl-navigation-color: $primary-disable;
$sl-caption-color: #fff;
$sl-caption-background: #000;

$sl-counter-fontsize: 1rem;
$sl-caption-fontsize: 1rem;
$sl-close-fontsize: 3rem;

$sl-breakpoint-medium: 35.5em; // 568px, when 1rem == 16px
$sl-breakpoint-large:  50em;   // 800px, when 1rem == 16px

$sl-arrow-fontsize-small:  2rem;
$sl-arrow-fontsize-medium: 3rem;
$sl-arrow-fontsize-large:  3rem;
$sl-img-border-small:  0 none;
$sl-img-border-medium: 0 none;
$sl-img-border-large:  0 none;
$sl-iframe-border-small:  0 none;
$sl-iframe-border-medium: 0 none;
$sl-iframe-border-large:  0 none;

$add-vendor-prefixes: true !default;

/* finally, import Bootstrap */
//@import "../../../node_modules/bootstrap";
//@import "~bootstrap/scss/bootstrap";
