/* assets/css/app.css */

.btn-success-1 {
    color: #fff;
    background-color: #46E0B1;
    border-color: #46E0B1;
    font-weight: bold;
    letter-spacing: 0.05em;
    border-radius: 0;
}

.btn-success-1:hover,
.btn-success-1:active,
.btn-success-1:focus,
.btn-success-1.active {
    /* let's darken #004E64 a bit for hover effect */
    background: #4DDEB0FF;
    color: #ffffff;
    border-color: #4DDEB0FF;
}

/*#footer-bg::after {*/
/*    content: "";*/
/*    background-image: url(http://192.168.238.53:8080/build/bmw-m3-interior.webp);*/
/*    position: absolute;*/
/*    left: 20rem;*/
/*    top: 0;*/
/*    width: 80rem;*/
/*    height: 100%;*/
/*    z-index: 1;*/
/*    opacity: 0.05;*/
/*    -webkit-background-size: cover;*/
/*    -moz-background-size: cover;*/
/*    -o-background-size: cover;*/
/*    background-size: cover;*/
/*}*/

.widget .item::after {
    content: "";
    background-image: url(http://192.168.238.53:8080/build/logo-triauto-item.svg);
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 30%;
    height: 4rem;
    opacity: 1;
    z-index: -1;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.separator {
    display: flex;
    align-items: center;
    text-align: center;
}

.separator::before,
.separator::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #e9f2fa;
}

.separator:not(:empty)::before {
    margin-right: .25em;
}

.separator:not(:empty)::after {
    margin-left: .25em;
}

.nav-underline .active:focus, .nav-underline .nav-link:focus {
    box-shadow: none!important;
}
.nav-underline .active {
    font-weight: bolder;
    border-bottom: 1px solid #1c6fb3!important;
    border-radius: unset;
    box-shadow: none!important;
}

#images-area .img-thumbnail {
    object-fit: cover;
    width: 100%!important;
    height: 7rem;
}

input.modal-title:focus {
    box-shadow: 0 0 0 0!important;

}
/*input.modal-title {*/
/*    border-style: dashed!important;*/
/*}*/

.carousel-caption {
    background: rgb(255,255,255);
    background: -moz-linear-gradient(
            0deg, rgba(255,255,255,0) 0%, rgba(0,0,0,0.6) 0%, rgba(255,255,255,0.0) 100%);
    background: -webkit-linear-gradient(
            0deg, rgba(255,255,255,0) 0%, rgba(0,0,0,0.6) 0%, rgba(255,255,255,0.0) 100%);
    background: linear-gradient(
            0deg, rgba(255,255,255,0) 0%, rgba(0,0,0,0.6) 0%, rgba(255,255,255,0.0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
}

.carouse-next-fade{
    position: absolute;
    right: 0px;
    top: 0px;
    height: 100%;
    width: 0.5rem!important;
    z-index: 2;
    filter: alpha(opacity = 80);
    background: linear-gradient(
            90deg, rgba(255,255,255,0) 0%, rgb(255 255 255) 100%, rgba(255,255,255,0.0) 0%
    );
}

.carouse-prev-fade{
    position: absolute;
    left: 0px;
    top: 0px;
    height: 100%;
    width: 1.5rem!important;
    z-index: 2;
    filter: alpha(opacity = 80);
    background: linear-gradient(
            -90deg, rgba(255,255,255,0) 0%, rgb(255 255 255) 100%, rgba(255,255,255,0.0) 0%
    );
}

.row.my-3.mx-2.sender:after {
    height: 0;
    content: "";
    top: -12px;
    left: 0px;
    position: relative;
    border-style: solid;
    border-color: #ffffff00 #ffffff00 #ffffff00 #e9f2fa;
    border-width: 11px 15px;
}

#review_form_stars .form-check.form-check-inline {
    padding: 0px;
    margin: 0px 5px;
}

.min-vh-75 {
    min-height: 75vh !important;
}

/* width */
.light-scroll::-webkit-scrollbar {
    width: 3px;
}

/* Track */
.light-scroll::-webkit-scrollbar-track {
    background: white;
}

/* Handle */
.light-scroll::-webkit-scrollbar-thumb {
    background: #D2E7F7;
}

.after-none::after {
    display: none!important;
}
.swiper-pagination-bullet {
    background-color: #E9F2FA;
    -webkit-transition: background 0.1s;
    -moz-transition: background 0.1s;
    -o-transition: background 0.1s;
    transition: background 0.1s;
}
.swiper-pagination-bullet-active {
    background-color: #48ACF0!important;
    -webkit-transition: background 0.1s;
    -moz-transition: background 0.1s;
    -o-transition: background 0.1s;
    transition: background 0.1s;
}

@media (min-width: 720px) {
    .grid-title-search {
        max-height: 4.5rem;
        min-height: 4.5rem;
    }
}
@media (max-width: 540px) {
    .grid-title-search {
        max-height: 4.5rem;
        min-height: 3.5rem;
    }
}

.sl-navigation {
    height: 5rem;
    background: #16588E;
}

.sl-triauto {
    opacity:0;
    top: 1.5rem;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
}

.sl-triauto.active {
    opacity: 1;
}

#images-area div:first-child .img-thumbnail {
    background: #48ACF0;
}
.btn-outline-primary:hover,.btn-outline-primary:active  {
    color: white!important;
}




