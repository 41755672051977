//@import "../../node_modules/bootstrap/scss/functions";
//@import "../../node_modules/bootstrap/scss/variables";
//@import "../../node_modules/bootstrap/scss/mixins";
//@import "../../node_modules/bootstrap/scss/buttons";
//@import "../../node_modules/bootstrap/scss/button-group";
@import './layout/app.css';
@import './helper/variables';


@each $state, $value in $theme-colors {
  .text-placeholder-#{$state}::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #{$value}!important;
    opacity: 1!important; /* Firefox */
  }
  .text-placeholder-#{$state}:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #{$value}!important;
  }
  .text-placeholder-#{$state}::-ms-input-placeholder { /* Microsoft Edge */
    color: #{$value}!important;
  }

  .bg-hover-#{$state} {
    transition: background-color 0.1s ease-out;
  }
  .bg-hover-#{$state}:hover {
    --bs-bg-opacity: 1;
    background-color: #{$value}!important;
  }
}

.pswp__bg {
  background: $dark-hover!important;
}

@import "../../node_modules/@fontsource/saira-semi-condensed/500.css"; // Weight 400.

@import "~bootstrap-icons/font/bootstrap-icons.css";
@import "~flag-icon-css/css/flag-icon.min.css";
@import "~daterangepicker/daterangepicker.css";
@import "../../node_modules/photoswipe/dist/photoswipe.css";
@import "../../node_modules/photoswipe/dist/default-skin/default-skin.css";
//@import "../../node_modules/simplelightbox/dist/simple-lightbox.min.css";
.pswp__button--arrow--left:before, .pswp__button--arrow--right:before {
  width: 0px;
  height: 0px;
}
//@import "~swiper/swiper";

//@import "~bootstrap/scss/bootstrap";

@import "../../node_modules/bootstrap/scss/bootstrap";
@import "../../node_modules/swiper/swiper";
@import "../../node_modules/simplelightbox/src/simple-lightbox";


//@import '../../node_modules/swiper/css';
//@import '../../node_modules/swiper/css/navigation';
//@import '../../node_modules/swiper/css/pagination';

